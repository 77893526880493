<template>
  <sm-editable-item
    v-model="form"
    :controllerName="controllerName"
    :isLoading="isLoadingPage"
    :breadcrumbs="breadcrumbs"
    :pageHeader="pageHeader"
    :fields="fields"
    :disabledSaveButton="isEqual"
    @save="onSave('TelemarketingCheckListQuestions')"
    @cancel="onCancel('TelemarketingCheckListQuestions')"
  />
</template>

<script>
// mixins
import editableListItemEdit from '@/mixins/editableListItemEdit.js';
// vuex
import { mapState, mapActions } from 'vuex';
// components
import SmEditableItem from '@/components/views/SmEditableItem.vue';

export default {
  name: 'TelemarketingCheckListQuestionsEdit',

  components: {
    SmEditableItem,
  },

  mixins: [editableListItemEdit],

  data() {
    return {
      isLoadingPage: false,
      controllerName: 'TelemarketingCheckListQuestions',
      breadcrumbs: [
        {
          text: 'Телемаркетинг',
          route: { name: 'TelemarketingMain' },
        },
        {
          text: 'Вопросы для чек-листа по телемаркетингу',
          route: { name: 'TelemarketingCheckListQuestions' },
        },
        {
          text: 'Редактирование вопроса для чек-листа по телемаркетингу',
        },
      ],
      pageHeader: 'Редактирование вопроса для чек-листа по телемаркетингу',
      form: {},
    };
  },

  computed: {
    ...mapState({
      item: (state) => state.editableList.item,
    }),

    fields() {
      return [
        {
          form: [
            {
              type: 'text',
              key: 'name',
              label: 'Название',
            },
          ],
        },
      ];
    },
  },

  created() {
    this.getItem({
      name: this.controllerName,
      id: this.id,
    })
      .then(() => {
        this.form = this.lodash.cloneDeep(this.item.data);
      })
      .finally(() => {
        this.isLoadingPage = false;
      });
  },

  methods: {
    ...mapActions({
      getItem: 'editableList/getItem',
      updateItem: 'editableList/updateItem',
    }),

    validateForm(form, mandatoryFields) {
      const emptyField = mandatoryFields.filter((item) => {
        return !form[item.key];
      });
      let message = null;
      if (emptyField.length) {
        message = `Не заполнены обязательные поля: ${emptyField
          .map((item) => item.label)
          .join(', ')}`;
      }
      return message;
    },

    onSave(route) {
      const mandatoryFields = [{ key: 'name', label: 'Название' }];

      const header = this.validateForm(this.form, mandatoryFields);
      if (header) {
        this.$notify({
          header,
          type: 'error',
          timer: 5000,
        });
        return;
      }

      this.isLoadingPage = true;

      this.updateItem({
        name: this.controllerName,
        payload: this.form,
      })
        .then((result) => {
          if (result.isSucceed) {
            this.$router.push({ name: route });
          }
        })
        .finally(() => {
          this.isLoadingPage = false;
        });
    },
  },
};
</script>
